@import url("https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500;600;700&family=Michroma&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .text-heading-1 {
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.01em;
  }
  .text-heading-2 {
    font-size: 40px;
    font-style: normal;
    line-height: 48px;
    letter-spacing: 0.4px;
  }
  .text-heading-3 {
    font-size: 32px;
    font-style: normal;
    line-height: 48px;
    letter-spacing: 0.32px;
  }

  .text-sub-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .text-body-1 {
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
  }

  .text-body-2 {
    font-size: 16px;
    font-style: normal;
    line-height: 21px;
    letter-spacing: 0.08px;
  }

  .text-body-3 {
    font-size: 14px;
    font-style: normal;
    line-height: 21px;
  }

  .text-body-4 {
    font-size: 12px;
    font-style: normal;
    line-height: 21px;
    letter-spacing: 0.06px;
  }

  .text-body-5 {
    font-size: 10px;
    line-height: 21px;
  }

  .text-nowrap {
    text-wrap: nowrap;
  }

  .card {
    @apply rounded-2xl border-[0.5px] border-[#99CBFF] bg-white shadow-[0px_5px_5px_0px_rgba(60,60,60,0.05)] px-4 py-[20px] md:px-[20px];
  }

  .card .card-header {
    @apply text-body-1 text-neutral-900 font-bold pb-4 border-b border-neutral-300;
  }

  .form-control {
    @apply p-2 text-body-4 border-[1px] border-solid border-neutral-400 rounded-lg bg-white md:text-body-3;
  }

  .form-control,
  .form-control > input {
    @apply focus:outline-none;
  }

  .form-control:disabled,
  .form-control.disabled {
    @apply bg-neutral-300 text-neutral-600;
  }

  .form-control::placeholder,
  .form-control > input::placeholder {
    @apply text-neutral-600 text-body-3;
  }

  .input-group {
    @apply flex relative flex-wrap items-stretch;
  }
  .input-group > .input-group-text {
    @apply flex items-center text-body-3 text-neutral-700 font-medium bg-[#FAFAFA] px-3 py-[6px] border-[1px] border-solid border-neutral-400;
  }

  .input-group > .form-control {
    @apply relative flex-auto min-w-0 w-[1%];
  }

  .input-group > :not(:first-child) {
    @apply rounded-s-none border-s-0;
  }

  .input-group > :not(:last-child) {
    @apply rounded-e-none;
  }

  .input-group > :first-child {
    @apply rounded-s-lg;
  }

  .input-group > :last-child {
    @apply rounded-e-lg;
  }
}

.Toastify__toast-body {
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  padding-block: 0.25rem;
}

.react-datepicker__view-calendar-icon input {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-up' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.react-resizable-handle.react-resizable-handle-se {
  cursor: pointer !important;
  background: none;
  padding: 0;
}

.react-resizable-handle.react-resizable-handle-se::after {
  display: none;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 70vw;
  }
  .Toastify__toast-container--top-right {
    right: 0.75rem;
    top: 5.25rem;
    left: auto;
  }
}

@media print {
  @page {
    size: landscape;
    margin: 16px;
  }
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

.react-select-error .react-select__control {
  @apply !border-red-500;
}

.semicircle-gauge .tick-line {
  @apply !hidden;
}
.semicircle-gauge svg g {
  transform: translate(70.76, 32) !important;
}
